.exploreAllModal .mediaItemContainer {
    width: 332px;
    height: 186px;
}
.exploreAllClose {
    position: relative;
    top: 30px;
    right: 30px;
    height: 16px;
    width: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}
.exploreAllClose::before,
.exploreAllClose::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
}
.exploreAllClose::before {
    transform: rotate(45deg);
}
.exploreAllClose::after {
    transform: rotate(-45deg);
}
.exploreAllMediaItem .mediaItemWrapper {
    margin: 5px !important;
}
@media only screen and (max-width: 800px) {
    .exploreAllTitle {
        font-size: 28px !important;
        padding: 57px 0px 20px 0 !important;
    }
    .exploreAllMediaItem .mediaItemWrapper {
        margin: 7.5px !important;
    }
}
