.tooltipText::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 31px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: rotate(45deg);
    background: #262626 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 25px #000000bf;
    border-bottom: 1px solid #404040;
    border-right: 1px solid #404040;
}
.favoritedIcon {
    display:inline-block;
    width: 22px;
    height:22px;
    border-radius:50%;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
}
.favoritedIcon:before{
    content:"";
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #FFFFFF;
    left: 12px;
    top: 2px;
}
.favoritedIcon:after{
    content:"";
    position: absolute;
    width: 8px;
    height: 3px;
    background-color: #FFFFFF;
    left: 7px;
    top: 14px;
}
.unfavoriteIcon {
    display:inline-block;
    width: 15px;
    height:3px;
    position: absolute;
    background-color: #FFFFFF;
}
