::placeholder {
    color: #A7A7A7;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #A7A7A7;
}
::-ms-input-placeholder {
    color: #A7A7A7;
}
input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    outline: none;
    color: #FFFFFF;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: rgba(255, 255, 255, 0.1) !important;
}
.productSlide .buttonContainer {
    margin-right: 0 !important;
}
