.carouselLeftArrow:hover {
    opacity: 1 !important;
}
.carouselRightArrow:hover {
    opacity: 1 !important;
}
.carouselContainer .slick-slider {
    align-items: center;
    justify-content: center;
    padding: 90px 90px 0 90px;
}
.carouselContainer .slick-list {
    margin: 0 35px 0 35px;
}
.carouselContainer .slick-dots {
    background-color: transparent;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    right: 10.25em;
    bottom: -1.5em;
}
.carouselContainer .slick-dots li {
    width: 10px;
    height: 14px;
}
.carouselContainer .slick-dots li button:before {
    color: #FFFFFF;
    opacity: 0.5;
}
.carouselContainer .slick-dots li.slick-active button:before {
    color: #FFFFFF;
    opacity: 1;
}
.carouselContainer .slide {
    background-color: #00008B;
}
@media only screen and (max-width: 1289px) {
    .carouselContainer {
        margin-top: 130px !important;
    }
    .carouselContainer .slick-slider {
        padding: 0 30px 0 30px !important;
    }
    .carouselContainer .slick-list {
        margin: 0 35px 0 35px !important;
    }
    .carouselContainer .slick-dots {
        right: 5em !important;
    }
}
@media only screen and (max-width: 800px) {
    .carouselContainer {
        margin-top: 0 !important;
    }
    .carouselContainer .slick-slider {
        padding: 0 18px 0 18px !important;
    }
    .carouselContainer .slick-list {
        margin: 0 18px 0 18px !important;
        padding-top: 18px !important;
    }
    .carouselContainer .slick-dots {
        right: 33px !important;
    }
    .carouselLeftArrowContainer {
        display: none !important;
    }
    .carouselRightArrowContainer {
        display: none !important;
    }
}

/*.carouselContainer .slick-slide {
    height: 100% !important;
}
.carouselContainer .slick-track {
    height: 100% !important;
}*/
