.dropdown-inner {
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
}

.dropdown > .dropdown-menu {
  width: 100%;
  transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
  visibility: hidden;
  opacity: 0;
  transform: scale(.95);
}

.dropdown-hidden {
  width: 100%;
  transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
  visibility: hidden !important;
  opacity: 0 !important;
  transform: scale(.95);
}

.dropdown:hover > .dropdown-menu {
  transition: opacity 0.3s, transform 0.3s;
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 825px) {
  .dropdown-inner {
    width: 100%;
  }
}