.mediaRowContainer:hover .mediaRowLeftArrowContainer {
    opacity: 1 !important;
}
.mediaRowContainer:hover .mediaRowRightArrowContainer {
    opacity: 1 !important;
}
.mediaRowContainer:hover .mediaRowLeftArrowContainer .mediaRowLeftArrow {
    opacity: 1 !important;
}
.mediaRowContainer:hover .mediaRowRightArrowContainer .mediaRowRightArrow {
    opacity: 1 !important;
}
.mediaRowContainer:hover .mediaRowLeftArrowContainer .mediaRowLeftArrow.hidden,
.mediaRowContainer:hover .mediaRowRightArrowContainer .mediaRowRightArrow.hidden {
    opacity: 0 !important;
    pointer-events: none !important;
}
.mediaRowContainer .slick-list {
    overflow: visible !important;
}
.mediaRowContainer .slick-track {
    display: flex !important;
    width: auto !important;
}
.mediaRowIcon {
    width: 36px;
    height: 22px;
    background-color: #FFFFFF;
    -webkit-mask: url('../../assets/Live Icon.svg') no-repeat center;
    mask: url('../../assets/Live Icon.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    align-self: center;
    justify-self: flex-start;
    margin-right: 12px;
    margin-top: 2px;
}
.mediaRowAll:hover {
    color: #FFFFFF !important;
}
@media only screen and (max-width: 1289px) {
    .mediaRowWrapper {
        margin-top: 0px !important;
    }
    .mediaRowContainer {
        margin: 25px 80px 0 80px !important;
    }
    .mediaRowIcon {
        width: 45px !important;
    }
}
@media only screen and (max-width: 800px) {
    .mediaRowWrapper {
        margin-top: 30px !important;
    }
    .mediaRowContainer {
        margin: 12px 35px !important;
    }
    .mediaRowTitle {
        font-size: 17px !important;
    }
    .mediaRowLeftArrowContainer {
        width: 70px !important;
        left: -35px !important;
        top: 10px !important;
        height: 98px !important;
    }
    .mediaRowRightArrowContainer {
        width: 70px !important;
        right: -35px !important;
        top: 10px !important;
        height: 98px !important;
    }
    .mediaRowAll {
        font-size: 14px !important;
    }
}
