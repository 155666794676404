.video-player-container {
}

.media-player-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.video-player-children {

}

.media-player-wrapper {
  height: 100%;
  min-width: 275px;
  pointer-events: auto;
}

.media-controls-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  padding: 2px 4px;
}

.media-controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgb(0 0 0 / 0.5);
}

.player.video {
  height: 100% !important;
  padding-bottom: 0px !important;
}

:where(media-outlet video) {
  height: 100% !important;
}

.vm-player {
  text-align: left;
}

.media-outlet {
  height: 100% !important;
}
  /*border-radius: 0 !important;
}

media-outlet {
  border-radius: 0px !important;
  border: none !important;
}*/

:root {
  --video-border-radius: 0px
}

.media-player {
  /* Player CSS variables (defaults shown). */
  --media-font-family: sans-serif;
  --media-controls-color: #f5f5f5;
  --media-focus-ring: 0 0 0 3px rgb(78 156 246);
}

.vds-button {
  /* CSS variables available for customization (defaults shown). */
  --media-button-color: var(--media-controls-color, #f5f5f5);
  --media-button-size: 40px;
  --media-button-icon-size: 80%;
  --media-button-padding: 0px;
  --media-button-border-radius: 8px;
  --media-button-hover-bg: rgb(255 255 255 / 0.2);
  --media-button-hover-transform: scale(1);
  --media-button-hover-transition: transform 0.2s ease-in;
  --media-button-touch-hover-border-radius: 100%;
  --media-button-touch-hover-bg: rgb(255 255 255 / 0.2);
}

.vds-button[data-pressed] .not-pressed-icon,
.vds-button:not([data-pressed]) .pressed-icon {
  display: none;
}

.vds-controls {
  /* CSS variables available for customizing this component. */
  /* Remove any you don't need, default values are shown below. */
  --media-controls-padding: 0px;
  --media-controls-in-transition: opacity 0.2s ease-in;
  --media-controls-out-transition: opacity 0.2s ease-out;
}

.vds-controls-group {
  display: flex;
  align-items: center;
  width: 100%;
  padding-inline: 8px;
}

.justify-center {
  justify-content: center;
}

.vds-controls-group:last-child {
  padding: 8px;
}
.vds-controls-group:first-child {
  padding: 8px;
}

.vds-controls .vds-time-slider {
  --media-slider-height: 7px;
}

.vds-controls .vds-time-slider .vds-slider-value {
  background-color: unset;
}

.vds-controls .vds-volume-slider {
  --media-slider-height: 40px;
  --media-slider-preview-offset: 32px;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 80px !important;
}

.vds-controls .vds-time-group {
  margin-inline: 8px;
}

.vds-controls {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.20) 13%, rgba(0,0,0,0) 40%);
}

.vds-live-button {
  /* CSS variables available for customization (defaults shown). */
  --media-live-button-width: 40px;
  --media-live-button-height: 40px;
  --media-live-button-bg: #8a8a8a;
  --media-live-button-border-radius: 2px;
  --media-live-button-color: #161616;
  --media-live-button-font-size: 12px;
  --media-live-button-font-weight: 600;
  --media-live-button-letter-spacing: 1.5px;
  --media-live-button-padding: 1px 4px;
  --media-live-button-edge-bg: #dc2626;
  --media-live-button-edge-color: #f5f5f5;

  margin-left: 8px;
}

[data-media-player] {
  height: 100%;
  background-color: black;
  border-radius: 5px;
}

[data-media-provider] video {
  height: 100% !important;
  object-fit: contain;
  border-radius: 4px;
}