::placeholder {
    color: #A7A7A7;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #A7A7A7;
}
::-ms-input-placeholder {
    color: #A7A7A7;
}
input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    outline: none;
    color: #FFFFFF;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: rgba(255, 255, 255, 0.1) !important;
}
.accountContainer .buttonContainer {
    margin-right: 0 !important;
}
.accountCancelBtnIcon {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
.accountCancelBtnIcon::before,
.accountCancelBtnIcon::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #FF4848;
}
.accountCancelBtnIcon::before {
    transform: rotate(45deg);
}
.accountCancelBtnIcon::after {
    transform: rotate(-45deg);
}
.accountSaveBtnIcon {
    display:inline-block;
    width: 100%;
    height: 100%;
    border-radius:50%;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.accountSaveBtnIcon:before{
    content: "";
    position: absolute;
    width: 2px;
    height: 19px;
    background-color: #55B685;
    left: 9px;
    top: 0px;
}
.accountSaveBtnIcon:after{
    content: "";
    position: absolute;
    width: 9px;
    height: 2px;
    background-color: #55B685;
    left: 1px;
    top: 17px;
}
.accountEditBtnIcon {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    -webkit-mask: url('../../assets/Edit Button.svg') no-repeat center;
    mask: url('../../assets/Edit Button.svg') no-repeat center;
    -webkit-mask-size: contain;
    mask-size: contain;
    transition: all .3s ease-in-out;
}
.accountCheckmark {
    display:inline-block;
    width: 22px;
    height:22px;
    border-radius:50%;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: 1px;
    margin-right: 1px;
}
.accountCheckmark:before{
    content:"";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #fff;
    left: 12px;
    top: 2px;
}
.accountCheckmark:after{
    content:"";
    position: absolute;
    width: 8px;
    height: 3px;
    background-color: #fff;
    left: 7px;
    top: 13px;
}
