::placeholder {
    color: #A7A7A7;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #A7A7A7;
}
::-ms-input-placeholder {
    color: #A7A7A7;
}
input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    outline: none;
    color: #FFFFFF;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: rgba(255, 255, 255, 0.1) !important;
}
.signInClose {
    position: absolute;
    top: 25px;
    right: 25px;
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}
.signInClose::before,
.signInClose::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
}
.signInClose::before {
    transform: rotate(45deg);
}
.signInClose::after {
    transform: rotate(-45deg);
}
.signInWrapper .slick-slider {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.signInWrapper .slick-slide.slick-active.slick-current {
    z-index: 1;
}
.signInWrapper .slick-slide.slick-active.slick-current > div > div {
    width: calc(-20px + 100%) !important;
}
.signInWrapper .slick-dots {
    bottom: 100px;
    pointer-events: none;
}
.signInWrapper .slick-dots .slick-active {
    margin-left: -5px;
}
@media (max-height: 700px) {
    .signInWrapper .slick-dots {
        bottom: 50px;
    }
}
.signInWrapper .slick-dots li {
    width: 10px;
    height: 14px;
}
.signInWrapper .slick-dots li button:before {
    color: #FFFFFF;
    opacity: 0.5;
}
.signInWrapper .slick-dots li.slick-active button:before {
    color: #FFFFFF;
    opacity: 1;
}
.signInWrapper .buttonContainer {
    margin-right: 0 !important;
}
.signInSlide {
    width: 350px !important;
    padding: 25px !important;
}
.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    border-radius:50%;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkmark:before{
    content:"";
    position: absolute;
    width: 2px;
    height: 13px;
    background-color: #fff;
    left: 12px;
    top: 2px;
}
.checkmark:after{
    content:"";
    position: absolute;
    width: 8px;
    height: 3px;
    background-color: #fff;
    left: 7px;
    top: 13px;
}
