.bar {
    height: 6px;
    width: 100%;
    background-color: rgba(180, 180, 180, 0.5);
    transition: all .2s ease-in-out;
}

.progress {
    height: 100%;
}
